let origin = "https://future.zhuneng.cn";
//提交心愿
const postWishUrl = `/placeApi/front/wishWall/add`;
//我的心愿
const myWishUrl = `/placeApi/front/wishWall/mineList`;
//心愿详情
const wishDetailUrl = `/placeApi/front/wishWall/detailInfo`;
//心愿墙
const wishWallUrl = `/placeApi/front/wishWall/list`;
export { postWishUrl, myWishUrl, wishDetailUrl, wishWallUrl };
