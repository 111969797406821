const wishType = [
  { key: 1, value: "愿望" },
  { key: 2, value: "祝福" },
  { key: 3, value: "其他" },
];

const auditStatusMap = [
  { key: 1, value: "审核通过" },
  { key: 2, value: "审核驳回" },
  { key: 0, value: "待审核" },
];

export { wishType, auditStatusMap };
